import Vue from "vue";
import Router from "vue-router";
import { hasRole, hasMultiplePermission } from "./store/modules/auth";
import { ROLES, PERMISSIONS } from "./utils/constant";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "dashboards/classic",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Auth Layout",
          path: "auth",
          component: () => import("@/views/login/Index"),
          children: [
            {
              name: "Login",
              path: "login",
              component: () => import("@/views/login/components/Login"),
            },
            {
              name: "ForgotPassword",
              path: "forgot-password",
              component: () =>
                import("@/views/login/components/ForgotPassword"),
            },
            {
              name: "ChangePassword",
              path: "change-password",
              component: () =>
                import("@/views/login/components/ChangePassword"),
              beforeEnter: (to, from, next) => {
                if (to.params.email) {
                  next();
                } else {
                  next("/auth/login");
                }
              },
            },
          ],
        },
      ],
    },
    {
      name: "Dashboards",
      path: "/dashboards",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Classic",
          path: "classic",
          component: () => import("@/views/dashboards/index"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      name: "User",
      path: "/user",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "UserProfile",
          path: "userProfile",
          component: () => import("@/views/userProfile/userProfile.page.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: "ChangePassword",
          path: "changePassword",
          component: () =>
            import("@/views/change-password/change-password.vue"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      name: "Customer",
      path: "/customer",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Customerlist",
          path: "customerlist",
          component: () => import("@/views/customer/index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_CUSTOMER],
          },
        },
        {
          name: "AcqCustomerList",
          path: "acq-customer-list",
          component: () => import("@/views/customer/index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_CUSTOMER],
          },
        },
        {
          name: "Supplierlist",
          path: "supplierlist",
          component: () => import("@/views/customer/Supplier"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_SUPPLIER],
          },
        },
        {
          name: "CustomerDetail",
          path: ":id",
          component: () => import("@/views/customer/edit/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_CUSTOMER, PERMISSIONS.VIEW_SUPPLIER],
          },
        },
        // {
        //   path: ':id',
        //   name: 'CustomerDetail',
        //   component: () => import('@/views/customer/CustomerDetail'),
        // }
      ],
    },
    {
      name: "Purchase",
      path: "/purchase",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Purchaselist",
          path: "list",
          component: () => import("@/views/purchase-order/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_PURCHASE_ORDER],
          },
        },
        {
          name: "CreatePurchaseOrder",
          path: "create",
          component: () => import("@/views/purchase-order/AddPurchaseOrder"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.CREATE_PURCHASE_ORDER],
          },
        },
        {
          path: "/",
          name: "LayoutTabs",
          components: {
            default: LayoutTabs,
          },
          children: [
            {
              name: "PurchaseOrderDetail",
              path: ":id",
              component: () =>
                import("@/views/purchase-order/PurchaseOrderDetail.vue"),
              meta: {
                requiresAuth: true,
                permission: [PERMISSIONS.VIEW_PURCHASE_ORDER],
              },
            },
          ],
        },
      ],
    },
    {
      name: "Inventory",
      path: "/inventory",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "InventoryList",
          path: "list",
          component: () => import("@/views/inventory/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_INVENTORY],
          },
        },
        {
          name: "Location",
          path: "location",
          component: () => import("@/views/inventory/Location.vue"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_INVENTORY],
          },
        },
        {
          name: "LocationItem",
          path: "location-item",
          component: () => import("@/views/inventory/LocationItem.vue"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_INVENTORY],
          },
        },
        {
          name: "TagLocator",
          path: "tag-locator/:id",
          component: () => import("@/views/tag-locator/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_INVENTORY],
          },
        },
      ],
    },
    {
      name: "Invoice",
      path: "/invoice",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "InvoiceList",
          path: "list",
          component: () => import("@/views/invoice/list/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_INVOICE_AND_QUOTE],
          },
        },
        {
          name: "CreateInvoice",
          path: "create",
          component: () => import("@/views/invoice/create/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.CREATE_INVOICE_AND_QUOTE],
          },
        },
        {
          name: "SaleReport",
          path: "sale-report",
          component: () => import("@/views/sale-report/list/Index.vue"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_SALES_REPORT],
          },
        },
        {
          path: "/",
          name: "LayoutTabs",
          components: {
            default: LayoutTabs,
          },
          children: [
            {
              name: "InvoiceDetails",
              path: ":id",
              component: () => import("@/views/invoice/edit/Index"),
              meta: {
                requiresAuth: true,
                permission: [PERMISSIONS.VIEW_INVOICE_AND_QUOTE],
              },
            },
          ],
        },
      ],
    },
    {
      name: "PickingAndDispatch",
      path: "/picking",
      component: () => import("@/layouts/full-layout/LayoutWithoutSidebar"),
      children: [
        {
          name: "PickingList",
          path: "list",
          component: () => import("@/views/picking-dispatch/list/PickingList"),
          // component: () => import("@/views/invoice/list/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_PICKING],
          },
        },
        {
          name: "DispatchList",
          path: "dispatch-list",
          component: () => import("@/views/picking-dispatch/list/DispatchList"),
          // component: () => import("@/views/invoice/list/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_DISPATCH],
          },
        },
      ],
    },
    {
      name: "VehicleAcquisitions",
      path: "/vehicleacquisitions",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "VehicleAcquisitionsList",
          path: "list",
          component: () => import("@/views/vehicle-acquisitions/list/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_VA, PERMISSIONS.VIEW_PICKUP],
          },
        },
        {
          name: "VehicleAcquisitionsStockList",
          path: "stocklist",
          component: () =>
            import("@/views/vehicle-acquisitions-stock/list/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.VIEW_VEHICLE_STOCK],
          },
        },
        // {
        //   path: "pickup",
        //   name: "VehicleAcquisitionsPickupList",
        //   component: () =>
        //     import("@/views/vehicle-acquisitions-pickup/list/Index"),
        //   meta: {
        //     requiresAuth: true,
        //     permission: ["VIEW PICKUP", "EDIT VA & PICKUP"],
        //   },
        // },
        {
          name: "CreateVehicleAcquisitions",
          path: "createacq",
          component: () => import("@/views/vehicle-acquisitions/create/Index"),
          meta: {
            requiresAuth: true,
            permission: [PERMISSIONS.CREATE_VA],
          },
        },
        {
          path: "/",
          name: "LayoutTabs",
          components: {
            default: LayoutTabs,
          },
          children: [
            {
              name: "VehicleAcquisitionsStockDetails",
              path: "stock/:id",
              component: () =>
                import("@/views/vehicle-acquisitions-stock/edit/Index"),
              meta: {
                requiresAuth: true,
                permission: [PERMISSIONS.VIEW_VEHICLE_STOCK],
              },
            },
            {
              name: "VehicleAcquisitionsDetails",
              path: ":id",
              component: () =>
                import("@/views/vehicle-acquisitions/edit/Index"),
              meta: {
                requiresAuth: true,
                permission: [PERMISSIONS.VIEW_VA, PERMISSIONS.VIEW_PICKUP],
              },
            },
          ],
        },
      ],
    },
    {
      name: "Authentication",
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "BoxedLogin",
          path: "boxedlogin",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "FullLogin",
          path: "fulllogin",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "BoxedRegister",
          path: "boxedregister",
          component: () => import("@/views/authentication/BoxedRegister"),
        },
        {
          name: "FullRegister",
          path: "fullregister",
          component: () => import("@/views/authentication/FullRegister"),
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
      ],
    },
    {
      name: "Settings",
      path: "/settings",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "UserList",
          path: "user",
          component: () => import("@/views/settings/user/Index"),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
        {
          name: "RoleList",
          path: "role",
          component: () => import("@/views/settings/role/Index"),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
        {
          name: "UpdateRole",
          path: "role/update/:id",
          component: () => import("@/views/settings/role/update/Index"),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
      ],
    },
    {
      name: "Administrator",
      path: "/administrator",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "ConnectAppToXero",
          path: "connect-app-to-xero",
          component: () => import("@/views/administrator/connect-xero/Index"),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
        {
          name: "CatalogManagement",
          path: "catalog-management",
          component: () =>
            import(
              "@/views/administrator/catalog-management/CatalogManagement.vue"
            ),
        },
        {
          name: "PartNameManagement",
          path: "management-partname",
          component: () =>
            import("@/views/administrator/part/PartNameManagement"),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
        {
          name: "PartNoManagement",
          path: "management-partno",
          component: () =>
            import("@/views/administrator/part/PartNoManagement"),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
        {
          name: "PartPriceManagement",
          path: "management-partprice",
          component: () =>
            import("@/views/administrator/part/PartPriceManagement"),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
        // {
        //   path: "",
        //   name: "Part Management",
        //   component: () =>
        //     import("@/layouts/layout/LayoutCatalogManagement.vue"),
        //   meta: {
        //     requiresAuth: true,
        //     role: "SUPER ADMIN",
        //     Pa: "123",
        //     headerTabConfig: {
        //       navItems: [
        //         {
        //           title: "Part Name",
        //           name: "Part Name Management",
        //           router: "management-partname",
        //         },
        //         {
        //           title: "Part Price",
        //           name: "Part Price Management",
        //           router: "management-partprice",
        //         },
        //       ],
        //     },
        //   },
        //   children: [

        //   ],
        // },
      ],
    },
    {
      name: "BackOrderPart",
      path: "/back-order-part",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "ThresholdSetting",
          path: "threshold-setting",
          component: () =>
            import(
              "@/views/back-order-part/threshold-setting/ThresholdSetting"
            ),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
        {
          name: "BackOrderList",
          path: "list",
          component: () => import("@/views/back-order-part/list/BackOrderList"),
          meta: {
            requiresAuth: true,
            role: ROLES.SUPER_ADMIN,
          },
        },
      ],
    },

    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";
import { getToken } from "./utils/auth";
import LayoutTabs from "@/layouts/layout/LayoutTabs.vue";

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     NProgress.start(800);
//   }
//   next();
// });
NProgress.configure({ showSpinner: false });
const whiteList = [
  "/auth/login",
  "/auth/forgot-password",
  "/auth/change-password",
]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  const hasToken = getToken("ezipart-token");
  NProgress.start(800);
  if (hasToken) {
    if (whiteList.indexOf(to.path) !== -1) {
      next({ path: "/" });
      NProgress.done();
    } else {
      // Check if the route requires a role
      if (to.meta.role && !hasRole(to.meta.role)) {
        next({ path: "*", query: { noAccess: true } }); // Redirect to a no-access page or show a message
        NProgress.done();
      } else if (
        to.meta.permission &&
        !hasMultiplePermission(to.meta.permission)
      ) {
        next({ path: "*", query: { noAccess: true } }); // Redirect to a no-access page or show a message
        NProgress.done();
      } else {
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/auth/login?redirect=${to.path}`);
    }
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
