import store from "../store";

export function hasRole(role) {
  return store.getters.userInfo.role === role;
}

export function hasPermission(permission) {
  return store.getters.userInfo.permission.includes(permission);
}

export function hasMultiplePermission(requiredPermissions) {
  const userPermissions = store.getters.userInfo.permission;
  if (Array.isArray(requiredPermissions)) {
    return requiredPermissions.some((permission) =>
      userPermissions.includes(permission)
    );
  }
  return userPermissions.includes(requiredPermissions);
}
