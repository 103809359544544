import { mapGetters } from "vuex";
import { hasPermission, hasMultiplePermission } from "@/store/modules/auth";
import { ROLES, PERMISSIONS } from "@/utils/constant";

export const PermissionMixin = {
  data() {
    return {
      ROLES,
      PERMISSIONS,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    isSupAdmin() {
      return this.userInfo.role === this.ROLES.SUPER_ADMIN;
    },
    isSalePerson() {
      return this.userInfo.role === this.ROLES.SALES_PERSON;
    },
    isPickingSupervisor() {
      return this.userInfo.role === this.ROLES.PICKING_SUPERVISOR;
    },
    isPicker() {
      return this.userInfo.role === this.ROLES.PICKER;
    },
    isDispatchSupervisor() {
      return this.userInfo.role === this.ROLES.DISPATCH_SUPERVISOR;
    },
    isDispatcher() {
      return this.userInfo.role === this.ROLES.DISPATCHER;
    },
    isAccounting() {
      return this.userInfo.role === this.ROLES.ACCOUNTING;
    },
    canSellItem() {
      return (
        this.userInfo.role === this.ROLES.SUPER_ADMIN ||
        this.userInfo.role === this.ROLES.SALES_PERSON
      );
    },
    canViewAllInvoice() {
      return (
        this.isSupAdmin ||
        this.isPicker ||
        this.isDispatcher ||
        this.isPickingSupervisor ||
        this.isDispatchSupervisor
      );
    },
  },
  methods: {
    hasPermission(permission) {
      return hasPermission(permission);
    },
    hasMultiplePermission(permissions) {
      return hasMultiplePermission(permissions);
    },
  },
};
