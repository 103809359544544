import request from "@/utils/request";
export function getListSaleReportSummary(data, signal) {
  return request({
    url: "/mgr/saleReport/getSummary",
    method: "post",
    data,
    signal,
  });
}
export function getInvoiceList(data, signal) {
  return request({
    url: "/mgr/saleReport/queryInvoicesList",
    method: "post",
    data,
    signal,
  });
}
export function quoteReport(data) {
  return request(
    {
      url: "/pdf/quoteReport",
      method: "post",
      data,
    },
    { responseType: "text" }
  );
}
