import request from "@/utils/request";

export function getInventoryPartsList(data, signal) {
  // console.log(data)
  return request({
    url: "/mgr/inventory/queryInventoryPartsList",
    method: "post",
    data,
    signal,
  });
}

export function getInventoryList(data, signal) {
  // console.log(data)
  return request({
    url: "/mgr/inventory/queryInventoryList",
    method: "post",
    data,
    signal,
  });
}

export function getbrowseVehiclesList(data, signal) {
  return request({
    url: "/mgr/inventory/browseVehicles/list",
    method: "post",
    data,
    signal,
  });
}

export function getStockByVehicleId(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/detail",
    method: "get",
    params: data,
  });
}

export function getStockList(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/getStockList",
    method: "get",
    params: data,
  });
}

export function addStockToInventory(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/commit",
    method: "post",
    data,
  });
}

export function commitInventoryItem(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/commitInInvoicePage",
    method: "post",
    data,
  });
}

export function commitNonInventoryItem(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/commitNonInventoryItem",
    method: "post",
    data,
  });
}

export function getTagNoDetail(data) {
  return request({
    url: "/mgr/inventory/tagNoSearchDetail",
    method: "get",
    params: data,
  });
}

export function addStockAjustment(data) {
  return request({
    url: "/mgr/inventory/adjust",
    method: "post",
    data,
  });
}

export function updateTagNoDetail(data) {
  return request({
    url: "/mgr/inventory/tagNoSaveDetail",
    method: "post",
    data,
  });
}

export function searchTagNo(data) {
  return request({
    url: "/mgr/inventory/tagNoSearch",
    method: "get",
    params: data,
  });
}

export function globalSearch(data) {
  return request({
    url: "/mgr/inventory/globalSearch",
    method: "get",
    params: data,
  });
}

export function saveStock(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/saveDetail",
    method: "post",
    data,
  });
}

export function getHistoryAndEvents(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/queryHistoryAndEvents",
    method: "post",
    data,
  });
}

export function getStockItemPicture(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/pictures",
    method: "get",
    params: data,
  });
}

export function getStockItemPartPicture(data) {
  return request({
    url: "/mgr/inventory/browseStock/part/pictures",
    method: "get",
    params: data,
  });
}

export function getInventoryItemPicture(data) {
  return request({
    url: "/mgr/inventory/pictures",
    method: "get",
    params: data,
  });
}
export function getInventoryItemWithLocation(data) {
  return request({
    url: "/mgr/inventory/getInventoryItems",
    method: "get",
    params: data,
  });
}
export function updateInventoryItem(data) {
  return request({
    url: "/mgr/inventory/updateInventoryItem",
    method: "post",
    data,
  });
}
export function printTagLocation(locationID) {
  return request({
    url: `/pdf/printLocation/${locationID}`,
    method: "get",
  });
}

export function getItemsOrdersFromInventory(data) {
  return request({
    url: "/mgr/inventory/tagNoSearchDetail",
    method: "get",
    params: data,
  });
}

export function deleteStockItem(stockId) {
  return request({
    url: `/mgr/inventory/browseVehicles/deleteStockItem/${stockId}`,
    method: "delete",
  });
}

export function searchStockItemByListTag(tagNoList) {
  return request({
    url: `/mgr/inventory/browseVehicles/searchStockItem`,
    method: "get",
    params: { tagNoList },
  });
}

export function searchInventoryItemByListTag(tagNoList) {
  return request({
    url: `/mgr/inventory/searchInventoryItem`,
    method: "get",
    params: { tagNoList },
  });
}

export function updateBulkLocation(data) {
  return request({
    url: `/mobile/inventory/itemManage/updateBulkLocation`,
    method: "put",
    data,
  });
}

export function deleteInventoryItemByTagNo(tagNoList) {
  return request({
    url: `/mgr/inventory/deleteMultipleInventoryItem`,
    method: "delete",
    params: { tagNoList },
  });
}

export function changePriceItemsList(data) {
  return request({
    url: `/mgr/inventory/changePriceItemList`,
    method: "put",
    data,
  });
}
