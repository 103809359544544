export const HTTP_CODE_SUCCESS = 200;
export const typeScreen = Object.freeze({
  tagNo: "tagLocator",
  stockDetail: "stockDetail",
});

export const VA_STATUSES = {
  OPEN: { key: "Open", value: 1 },
  CLOSED: { key: "Closed", value: 2 },
  CANCELLED: { key: "Cancelled", value: 3 },
  IN_PROGRESS: { key: "In Progress", value: 4 },
  COMPLETED: { key: "Completed", value: 5 },
};

export const invoiceStatusEnum = {
  open: 1,
  closed: 3,
  cancelled: 4,
  finalized: 5,
  "In Progress": 6,
  completed: 7,
  confirmed: 8,
};

export const INVOICE_STATUSES = {
  OPEN: { key: "Open", value: 1 },
  CLOSED: { key: "Closed", value: 3 },
  CANCELLED: { key: "Cancelled", value: 4 },
  FINALIZED: { key: "Finalized", value: [2, 5] },
  IN_PROGRESS: { key: "In Progress", value: 6 },
  COMPLETED: { key: "Completed", value: 7 },
  CONFIRMED: { key: "Confirmed", value: 8 },
};

export const DISPATCH_STATUSES = {
  OPEN: { key: "Open", value: 1 },
  IN_PROGRESS: { key: "In Progress", value: 2 },
  COMPLETED: { key: "Completed", value: 3 },
};

export const DELIVERY_STATUSES = {
  OPEN: { key: "Open", value: 1 },
  IN_PROGRESS: { key: "Out for Delivery", value: 2 },
  DELIVERED: { key: "Delivered", value: 3 },
};

export const TRANSACTION_TYPES = {
  ACQ: "ACQ",
  INVOICE: "INV",
  PICKING: "Picking",
  DISPATCH: "Dispatch",
  DELIVERY: "Delivery",
  QUOTE: "Q",
  PURCHASE_ORDER: "PO",
};

export const ORDER_BY = {
  ASC: "ASC",
  DESC: "DESC",
};

export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";

export const ROLES = {
  SUPER_ADMIN: "SUPER ADMIN",
  SALES_MANAGEMENT: "SALE MANAGEMENT",
  SALES_PERSON: "SALES PERSON",
  ACCOUNTING: "ACCOUNTING",
  BUYING_DEPARTMENT: "BUYING DEPARTMENT",
  INVENTORY_PERSON: "INVENTORY PERSON",
  DISPATCHER: "DISPATCHER",
  DRIVER: "DRIVER",
  PICKER: "PICKER",
  PICKING_SUPERVISOR: "PICKING SUPERVISOR",
  DISPATCH_SUPERVISOR: "DISPATCH SUPERVISOR",
  CAR_SUPERVISOR: "CAR_SUPERVISOR",
};

export const PERMISSIONS = {
  VIEW_CUSTOMER: "VIEW CUSTOMER",
  CREATE_CUSTOMER: "CREATE CUSTOMER",
  EDIT_CUSTOMER: "EDIT CUSTOMER",
  VIEW_CUSTOMER_REPORT: "VIEW CUSTOMER REPORT",
  VIEW_SUPPLIER: "VIEW SUPPLIER",
  CREATE_SUPPLIER: "CREATE SUPPLIER",
  EDIT_SUPPLIER: "EDIT SUPPLIER",
  VIEW_SALE_REVENUE: "VIEW SALE REVENUE",
  VIEW_VEHICLE_AND_STOCK_REPORT: "VIEW VEHICLE & STOCK REPORT",
  VIEW_PURCHASE_ORDER: "VIEW PURCHASE ORDER",
  CREATE_PURCHASE_ORDER: "CREATE PURCHASE ORDER",
  VIEW_PICKUP: "VIEW PICKUP",
  EDIT_PURCHASE_ORDER: "EDIT PURCHASE ORDER",
  VIEW_INVOICE_AND_QUOTE: "VIEW INVOICE & QUOTE",
  CREATE_INVOICE_AND_QUOTE: "CREATE INVOICE & QUOTE",
  DELETE_INVOICE_AND_QUOTE: "DELETE INVOICE & QUOTE",
  EDIT_INVOICE_AND_QUOTE: "EDIT INVOICE & QUOTE",
  SEND_EMAIL_INVOICE_AND_QUOTE: "SEND EMAIL INVOICE & QUOTE",
  PRINT_INVOICE_AND_QUOTE: "PRINT INVOICE & QUOTE",
  VIEW_INVENTORY: "VIEW INVENTORY",
  EDIT_INVENTORY: "EDIT INVENTORY",
  VIEW_SALES_REPORT: "VIEW SALES REPORT",
  EDIT_PART: "EDIT PART",
  VIEW_VEHICLE_STOCK: "VIEW VEHICLE STOCK",
  EDIT_VEHICLE_STOCK: "EDIT VEHICLE STOCK",
  VIEW_VA: "VIEW VA",
  CREATE_VA: "CREATE VA",
  EDIT_VA_AND_PICKUP: "EDIT VA & PICKUP",
  VIEW_PART: "VIEW PART",
  VIEW_BACK_ORDER_PART: "VIEW BACK ORDER PART",
  EDIT_BACK_ORDER_PART: "EDIT BACK ORDER PART",
  VIEW_PICKING: "VIEW PICKING",
  VIEW_DISPATCH: "VIEW DISPATCH",
};

export const NON_REFRESH_ROUTES = ["InventoryList"];

export const TERMS = {
  CASH: { key: "Cash", value: 1 },
  CAPRICORN: { key: "Capricorn", value: 2 },
  ACCOUNT: { key: "Account", value: 3 },
  COD: { key: "COD", value: 4 },
};

export const CONTACT_TYPES = {
  CUSTOMER: "Customer",
  SUPPLIER: "Supplier",
};

export const PART_TYPES = {
  NEW_GENUINE: {
    key: "New Genuine",
    value: 1,
  },
  AFTERMARKET: {
    key: "Aftermarket",
    value: 2,
  },
  USED: {
    key: "Used",
    value: 3,
  },
  RECO: {
    key: "Reco",
    value: 4,
  },
};

export const STRIP_LIST_STEP = {
  SEARCH: 1,
  CHOOSE_PARTS: 2,
  CHOOSE_PARTS_DETAILS: 3,
};
