import { parseJSON } from "@/utils/common";

const state = () => {
  return {
    stripListData: parseJSON(sessionStorage.getItem("stripListData"), null),
    step1: {
      recentSearches: parseJSON(
        localStorage.getItem("strip-list-recent-searches"),
        []
      ),
    },
  };
};

const mutations = {
  SET_CACHE_DATA(state, payload) {
    state.stripListData = payload;
  },
  SET_RECENT_SEARCHES(state, payload) {
    state.step1.recentSearches = payload;
  },
  PREV_STEP(state) {
    if (!state.stripListData) return;
    const stepKeys = Object.keys(state.stripListData)
      .filter((key) => key.startsWith("step"))
      .map((key) => Number(key.replace("step", "")))
      .sort((a, b) => a - b);

    if (stepKeys.length > 1) {
      // Xoá phần tử cuối cùng (step cao nhất)
      const lastStep = stepKeys.pop();

      // delete state.stripListData[`step${lastStep}`];
      delete state.stripListData[`step${lastStep}`];
      state.stripListData = { ...state.stripListData };
    } else {
      state.stripListData = null;
    }

    sessionStorage.setItem(
      "stripListData",
      JSON.stringify(state.stripListData)
    );
  },
};

const actions = {
  setCacheData({ state, commit }, { step, key, data }) {
    const updatedStripListData = {
      ...(state.stripListData || {}),
      [`step${step}`]: key
        ? {
            ...(state.stripListData?.[`step${step}`] || {}),
            [key]: data,
          }
        : data,
    };
    // Commit mutation với dữ liệu đã cập nhật
    commit("SET_CACHE_DATA", updatedStripListData);

    // Lưu vào cookies
    sessionStorage.setItem(
      "stripListData",
      JSON.stringify(updatedStripListData)
    );
    console.log("here: ", updatedStripListData);
  },
  setRecentSearches({ state, commit }, payload) {
    let recentSearches = [...state.step1.recentSearches];
    recentSearches = recentSearches.filter((item) => item !== payload);

    if (recentSearches.length >= 10) recentSearches.pop();
    recentSearches.unshift(payload);

    localStorage.setItem(
      "strip-list-recent-searches",
      JSON.stringify(recentSearches)
    );

    commit("SET_RECENT_SEARCHES", recentSearches);
  },
  goToPreviousStep({ commit }) {
    commit("PREV_STEP");
  },
};

const getters = {
  getStripListFromCache(state) {
    return state.stripListData;
  },
  getRecentSearches(state) {
    return state.step1.recentSearches;
  },
  currentStep(state) {
    if (!state.stripListData) return 1;

    // Lấy danh sách các step (dạng "step1", "step2", ...)
    const stepKeys = Object.keys(state.stripListData)
      .filter((key) => key.startsWith("step")) // Chỉ lấy những key dạng "stepX"
      .map((key) => Number(key.replace("step", ""))) // Chuyển "stepX" thành số X
      .sort((a, b) => a - b); // Sắp xếp tăng dần

    return stepKeys.length ? Number(`${stepKeys[stepKeys.length - 1]}`) : 1;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
