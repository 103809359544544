import moment from "moment";
import Cookies from "js-cookie";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "./constant";

// format price $1, -$1
export function formatPrice(value) {
  // convert into number if value is string
  const numberValue =
    typeof value === "string" ? parseFloat(value) : Number(value);

  // check if numberValue is NaN
  if (isNaN(numberValue)) {
    return "Invalid value";
  }

  if (numberValue >= 0) {
    return `$${numberValue.toFixed(2)}`;
  } else {
    return `-$${Math.abs(numberValue).toFixed(2)}`;
  }
}

// format dates
const isValidDate = (date) => moment(date, moment.ISO_8601, true).isValid();

const formatSingleDate = (date, dateFormat) => {
  if (isValidDate(date)) {
    return moment(date).format(dateFormat);
  }
  return ""; // Default return for invalid date
};

export function formatDates(dates, dateTimeFormat = false) {
  const dateFormat = dateTimeFormat ? DATE_TIME_FORMAT : DATE_FORMAT;
  if (Array.isArray(dates)) {
    return dates.map((date) => formatSingleDate(date, dateFormat));
  }
  return formatSingleDate(dates, dateFormat);
}

// convert local date
export function convertToLocalDate(dateInput) {
  const date = new Date(dateInput);

  if (isNaN(date.getTime())) {
    throw new Error(
      "Invalid date input. Please provide a valid date string or Date object."
    );
  }
  const localOffset = date.getTimezoneOffset() * 60000;
  const localDate = new Date(date.getTime() - localOffset);
  return localDate.toISOString().split("T");
}

//handle error api
export function handleApiError(error, showNotification) {
  console.error(error.message);
  if (showNotification) {
    const errorMessage =
      error.response?.data?.errorMessage || "An error occurred";
    showNotification("error", errorMessage);
  }
}

// save last page
export function saveLastPageToLocalStorage(pageName, id, idNo) {
  const latestPages = JSON.parse(localStorage.getItem("latestPages")) || {};
  latestPages[pageName] = { id: id, name: idNo };
  localStorage.setItem("latestPages", JSON.stringify(latestPages));
}

// convert title
export function convertToTitleCase(key) {
  return key
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^\w/, (c) => c.toUpperCase());
}

export function onMakeChange(value, makeOptions) {
  return findSelectedLabel(value, makeOptions);
}
export function onModelChange(value, modelOptions) {
  return findSelectedLabel(value, modelOptions);
}
export function onSeriesChange(value, seriesOptions) {
  return findSelectedLabel(value, seriesOptions);
}

export function findSelectedLabel(id, data) {
  return data.find((item) => item.id === id)?.name;
}

export function getCookies(key) {
  return Cookies.get(key);
}

export function setCookies(key, value) {
  console.log({ key, value });

  return Cookies.set(key, value);
}

export const parseJSON = (value, defaultValue) => {
  try {
    return JSON.parse(value) ?? defaultValue;
  } catch {
    return defaultValue;
  }
};
