import Vue from "vue";
import Vuebar from "vuebar";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import "./plugins/base";
import api from "./api/index";
import "./services/firebase/firebaseConfig";
import { checkNotificationPermission } from "./services/firebase/cloud-messaging/firebase-messaging";
import "./services/broad-cast-channel";
// import { NON_REFRESH_ROUTES } from "./utils/constant";

// const isReLoadPage = new Set(NON_REFRESH_ROUTES);

// document.addEventListener("visibilitychange", function () {
//   if (!document.hidden && !isReLoadPage.has(router.currentRoute.name)) {
//     window.location.reload();
//   }
// });

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      await navigator.serviceWorker.register("/firebase-messaging-sw.js", {
        scope: "/",
      });

      // Kiểm tra quyền Notification
      const permission = await Notification.requestPermission();
      console.log("Connect SW successfully!");

      await checkNotificationPermission(permission);
    } catch (error) {
      console.error("Can't register Service Worker:", error);
    }
  }
};

async function checkCookieAndAct() {
  // Function to get a specific cookie by name
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  // Check for specific cookies
  const ezipartUserName = localStorage.getItem("ezipart-userName");
  const ezipartToken = getCookie("ezipart-token");
  const ezipartUserId = getCookie("ezipart-userId");

  if ((!ezipartToken || !ezipartUserId) && ezipartUserName) {
    await store.dispatch("user/logoutHandler");
  }
}

// print
import VueHtmlToPaper from "vue-html-to-paper";
const optionsHTMLToPaper = {
  styles: [
    // "https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css",
    "https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css",
  ],

  autoClose: false, // if false, the window will not close after printing
};
Vue.use(VueHtmlToPaper, optionsHTMLToPaper);

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.prototype.$api = api;
Vue.prototype.$uuid = uuidv4;

Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);

Vue.filter("price", function (value) {
  if (!value) return "0.00";
  // if(value >= 0){
  return Number(value).toFixed(2);
  // }else{
  //   return `-${Math.abs(Number(value)).toFixed(2)}`
  // }
});
Vue.filter("dommmyyyy", (isoStr) =>
  isoStr ? moment(isoStr).format("Do MMM YYYY") : ""
);

const options = {
  // You can set your default options here
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  timeout: 1000,
  newestOnTop: true,
  closeButton: false,
};
Vue.use(Toast, options);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

(async () => {
  await checkCookieAndAct();
  await store.dispatch("stompClient/connectWS");
  registerServiceWorker();
})();
