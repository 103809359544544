import request from "@/utils/request";

export function getOtherChargesList(data, signal) {
  return request({
    url: "/mgr/otherCharges/listOtherCharges",
    method: "get",
    params: data,
    signal,
  });
}

export function createOrUpdateOtherCharges(data) {
  return request({
    url: "/mgr/otherCharges/createOrUpdateOtherCharges",
    method: "post",
    data,
  });
}

export function getDetailOtherCharges(data) {
  return request({
    url: `/mgr/otherCharges/getDetailOtherCharges/${data}`,
    method: "get",
  });
}

export function createOtherChargeType(data) {
  return request({
    url: "/mgr/otherCharges/addOtherChargeType",
    method: "post",
    data,
  });
}

export function updateOtherChargeType(data) {
  return request({
    url: "/mgr/otherCharges/editOtherChargeType",
    method: "put",
    data,
  });
}

export function deleteOtherChargeType(otherChargeId) {
  return request({
    url: `/mgr/otherCharges/deleteOtherChargeType/${otherChargeId}`,
    method: "delete",
  });
}
