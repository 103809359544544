export default {
  methods: {
    showNotification(status, msg) {
      const _self = this;
      const module = {
        success: function () {
          _self.$toast.success(msg, {
            closeOnClick: true,
            icon: "fas fa-check-circle",
          });
        },
        error: function () {
          _self.$toast.error(msg, {
            closeOnClick: true,
            icon: "fas fa-exclamation-circle",
          });
        },
        warning: function () {
          _self.$toast.warning(msg, {
            closeOnClick: true,
            icon: "fas fa-exclamation-triangle",
          });
        },
      };
      module[status]();
    },
  },
};
